import React from 'react';
import CountdownTimer from './CountdownTimer';

const App: React.FC = () => {
    return (
        <div className="h-screen flex items-center justify-center bg-gray-100">
            <CountdownTimer />
        </div>
    );
};

export default App;
