import React, { useEffect, useState } from 'react';
import bgImage from './assets/reunion-bg.webp';

interface TimeLeft {
    weeks: number;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

const CountdownTimer: React.FC = () => {
    const calculateTimeLeft = (): TimeLeft => {
        const difference = +new Date('2024-09-15') - +new Date();
        let timeLeft: TimeLeft = {
            weeks: 0,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };

        if (difference > 0) {
            timeLeft = {
                weeks: Math.floor(difference / (1000 * 60 * 60 * 24 * 7)),
                days: Math.floor((difference / (1000 * 60 * 60 * 24)) % 7),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    return (
        <div
            className="min-h-screen w-full flex flex-col items-center justify-center text-center space-y-4 bg-cover bg-center bg-no-repeat bg-fixed py-10 px-4"
            style={{
                backgroundImage: `url(${bgImage})`,
            }}
        >
            <h1 className="text-4xl md:text-6xl font-bold font-great-vibes text-gray-800 text-shadow-lg">
                Katha + Richard Reunion
            </h1>
            <div className="text-2xl md:text-4xl lg:text-6xl font-bold text-white p-4 bg-opacity-75 bg-gray-800 rounded-md">
                <div className="flex flex-wrap justify-center gap-4 md:gap-8 lg:gap-12">
                    <div className="flex flex-col items-center">
                        <span>{timeLeft.weeks}</span>
                        <span className="text-sm">Weeks</span>
                    </div>
                    <div className="flex flex-col items-center">
                        <span>{timeLeft.days}</span>
                        <span className="text-sm">Days</span>
                    </div>
                    <div className="flex flex-col items-center">
                        <span>{timeLeft.hours}</span>
                        <span className="text-sm">Hours</span>
                    </div>
                    <div className="flex flex-col items-center">
                        <span>{timeLeft.minutes}</span>
                        <span className="text-sm">Minutes</span>
                    </div>
                    <div className="flex flex-col items-center">
                        <span>{timeLeft.seconds}</span>
                        <span className="text-sm">Seconds</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CountdownTimer;